import React from 'react'

const ArcRule = () => (
  <svg className="arc-rule" preserveAspectRatio="none" viewBox="0 0 1280 33" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Canvas" fill="none">
      <g id="Line (Stroke)">
        <path fillRule="evenodd" clipRule="evenodd" d="M 1280.2 25.5036C 776.459 -8.22046 495.738 -2.26619 0 32.0001L 0 25.5038C 495.88 -8.77225 776.329 -8.22915 1280.2 25.5036Z" transform="translate(-0.103516 0.499878)" fill="#E9E9E9"/>
      </g>
    </g>
  </svg>
)

export default ArcRule
