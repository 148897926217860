import React from 'react'
import Link from 'gatsby-link'
import ArcRule from '../components/ArcRule'
import BlogEntry from '../components/blogEntry'
import Layout from '../components/layout'
import { graphql } from 'gatsby'

const IndexPage = ({ location, data }) => (
  <Layout location={location}>
    <div>
      {
        location.hash === '#contact-ok' && (
          <div className="top-message">
            <span>Your message has been sent. Thanks!</span>
          </div>
        )
      }
      <section className="pitch">
        <p>
          Visual Perspective combines expertise in data science,
          visualization, GIS, and remote sensing.
        </p>
        <p>
          Need help making a map, interactive visualization,
          or just making sense of your data?
        </p>
      </section>
      <section className="contact-us">
        <ArcRule />
        <Link to="/contact" className="btn">Contact Us</Link>
      </section>
      <div className="horizontal-sections">
        <section className="work">
          <h2>Our Work</h2>
          <ul className="thumbs">
            <li className="population-migration full-width">
              <div className="inner">
                <a href="/migration/">
                  <div className="text-content">
                    <h3>
                      Forced Population Migration
                    </h3>
                    <p>
                      <span>An interactive report showing the results of our initial modeling efforts to simulate a hypothetical forced migration event in Yemen.</span>
                    </p>
                  </div>
                </a>
              </div>
            </li>
            <li className="iceland-vegetation full-width">
              <div className="inner">
                <a href="https://iceland.visualperspective.io/">
                  <div className="text-content">
                    <h3>
                      Iceland Vegetation
                    </h3>
                    <p>
                      <span>An open source visualizer for remote sensing data with space and time dimensions. Built with WebGL, D3, React, Regl, and Typescript.</span>
                    </p>
                  </div>
                </a>
              </div>
            </li>
            <li className="globe-viewer">
              <div className="inner">
                <a href="http://visualperspective.github.io/globe-viewer/index.html">
                  <div className="text-content">
                    <h3>
                      Globe Viewer
                    </h3>
                    <p>
                      <span>Open source globe visualization combining multiple open data sources. Built with WebGL, D3, Vue.js, Webpack, and ES6.</span>
                    </p>
                  </div>
                </a>
              </div>
            </li>
            <li className="web-worker">
              <div className="inner">
                <a href="http://visualperspective.github.io/web-worker-projection/">
                  <div className="text-content">
                    <h3>WebWorker D3 Benchmark</h3>
                    <p>
                      <span>An SVG and Canvas benchmark that tests the rendering of maps using D3 geo projections. Uses WebWorkers for parallel calculations.</span>
                    </p>
                  </div>
                </a>
              </div>
            </li>
          </ul>
        </section>
        <section className="services">
          <h2>Blog</h2>
          <div className="content">
            <ul className="blog-list blog-list-compact">
              {
                data.allMarkdownRemark.edges.map((post, i) => (
                  <BlogEntry key={i} {...post.node.frontmatter} />
                ))
              }
            </ul>
          </div>
          <h2>About Us</h2>
          <div className="content">
            <p>
              We have years of experience delivering
              applications, dashboards, maps, analysis, and reports
              based on data sets of all sizes.
              We take projects through design, analysis, and implementation.
            </p>
            <dl className="tag-lists">
              <dt>Web</dt>
              <dd>
                <ul>
                  <li>React</li>
                  <li>D3</li>
                  <li>Vue</li>
                  <li>WebGL</li>
                  <li>SVG</li>
                  <li>Webpack</li>
                </ul>
              </dd>
              <dt>GIS</dt>
              <dd>
                <ul>
                  <li>PostGIS</li>
                  <li>Turf.js</li>
                  <li>Leaflet</li>
                  <li>Google Maps</li>
                </ul>
              </dd>
              <dt>Tools</dt>
              <dd>
                <ul>
                  <li>Photoshop</li>
                  <li>Illustrator</li>
                  <li>Figma</li>
                  <li>Blender</li>
                  <li>QGIS</li>
                </ul>
              </dd>
              <dt>Amazon AWS</dt>
              <dd>
                <ul>
                  <li>EC2</li>
                  <li>S3</li>
                  <li>RDS</li>
                  <li>Cloudfront</li>
                </ul>
              </dd>
              <dt>Google Cloud</dt>
              <dd>
                <ul>
                  <li>Compute</li>
                  <li>Firebase</li>
                  <li>Cloud Storage</li>
                </ul>
              </dd>
              <dt>Machine Learning</dt>
              <dd>
                <ul>
                  <li>Convolutional neural networks</li>
                  <li>Deep neural networks</li>
                  <li>Random forests</li>
                  <li>Linear models</li>
                  <li>Regression</li>
                </ul>
              </dd>
            </dl>
          </div>
        </section>
      </div>
    </div>
  </Layout>
)

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(limit: 1, sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          frontmatter {
            title
            path
            date
            blurb
            blurbImage
          }
        }
      }
    }
  }
`

export default IndexPage
